export const overrideDataFile = (datafile: any) => {
  if (localStorage.getItem("disabledFlags") === null) {
    localStorage.setItem("disabledFlags", "");
  }
  if (localStorage.getItem("enabledFlags") === null) {
    localStorage.setItem("enabledFlags", "");
  }
  const disabledKeys = localStorage.getItem("disabledFlags")?.split(",") || [];
  const enabledKeys = localStorage.getItem("enabledFlags")?.split(",") || [];
  const disabledFound: any = datafile.featureFlags.filter((flag: any) =>
    disabledKeys.includes(flag.key)
  );
  const enabledFound = datafile.featureFlags.filter((flag: any) =>
    enabledKeys.includes(flag.key)
  );
  return {
    ...datafile,
    rollouts: datafile.rollouts
      .filter(
        (r: any) =>
          !disabledFound.map(({ rolloutId }: any) => rolloutId).includes(r.id)
      )
      .map((rollOut: any) => {
        if (
          enabledFound
            .map(({ rolloutId }: any) => rolloutId)
            .includes(rollOut.id)
        ) {
          return {
            ...rollOut,
            experiments: rollOut.experiments.map((exp: any) => {
              if (exp.key.startsWith("default-rollout")) {
                return {
                  ...exp,
                  variations: exp.variations.map((variation: object) => ({
                    ...variation,
                    featureEnabled: true,
                  })),
                };
              }
              return exp;
            }),
          };
        }
        return rollOut;
      }),
  };
};

export const clearOverride = () => {
  localStorage.setItem("disabledFlags", "");
  localStorage.setItem("enabledFlags", "");
};

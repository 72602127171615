const commonConfig = {
  brex: "andromeda",
  toast: "toast",
  vista: "vistaprint",
  app: "level",
  stagex: "level",
  dashboards: "level",
  qagpt: "vistaprint",
  "3degrees": "threedegrees",
  "3degreessandbox": "threedegreessandbox",
  thefarmersdog: "farmersdog",
  partner: "asuriondemo",
  uber: "uberprod",
};

const tenantConfig = {
  pe: { ...commonConfig },
  platform: { ...commonConfig },
  analytics: { ...commonConfig },
  ai: { ...commonConfig },
  dev: { ...commonConfig, uber: "uber" },
  staging: { ...commonConfig },
  stagex: { ...commonConfig, uber: "uber" },
  replica: { ...commonConfig },
  qa: { ...commonConfig },
  uat: { ...commonConfig },
  sandbox: { ...commonConfig },
  default: { ...commonConfig },
};

export default tenantConfig;

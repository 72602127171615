export const POD_NAMES = {
  PE_POD: "pe",
  PLATFORM_POD: "platform",
  ANALYTICS_POD: "analytics",
  AI_POD: "ai",
};

export const DEPLOY_ENV_NAMES = {
  DEV: "dev",
  STAGING: "staging",
  STAGEX: "stagex",
  REPLICA: "replica",
  QA: "qa",
  UAT: "uat",
  SANDBOX: "sandbox",
};
